import React from "react";
import { Link } from "react-router-dom";
import "./Entypeform_home.css";
import { ReactTypeformEmbed } from 'react-typeform-embed';
//import "../Encods"

function Entypeform_home(props) {
  const { logo_V, x3, proveYourself, line1, text7 } = props;

  return (
    <div className="en-typeform-home screen ">
      <Link to="../codes">
        <img className="logov7" src={logo_V} />
      </Link>
      <ReactTypeformEmbed url="https://form.typeform.com/to/jTuENbJM" />
    </div>
  );
}

export default Entypeform_home;
