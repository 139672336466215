import React from "react";
import { Link } from "react-router-dom";
import "./Entypeform_fail.css";

function Entypeform_fail(props) {
  const { logo_V, line1, x105413X, leave, oops } = props;

  return (
    <div className="en-typeform-fail screen ">
      <Link to="/en-home">
        <img className="logov9" src={logo_V} />
      </Link>
      <img className="line-13" src={line1} />
      <img className="x105413x" src={x105413X} />
      <Link to="/en-typeform-pass">
        <div className="component-45-4">
          <div className="leave poppins-normal-licorice-25px">{leave}</div>
        </div>
      </Link>
      <div className="oops argentcf-super-normal-licorice-45px">{oops}</div>
    </div>
  );
}

export default Entypeform_fail;
