import React from "react";
import { Link } from "react-router-dom";
import Component701 from "../Component701";
import "./Enhome.css";

function Enhome(props) {
  const { logo_V, x1, asset1, title, text1, text2, croods5, getInviteCode, component701Props } = props;

  return (
    <div className="en-home screen ">
      <img className="logov3" src={logo_V} />
      <div className="component-71-1">
        <img className="x1" src="./1@1x.png" />
        <div className="asset-1" style={{ backgroundImage: `url(${asset1})` }}></div>
        <div className="component-43-1">
          <h1 className="title argentcf-super-normal-licorice-50px">{title}</h1>
        </div>
      </div>
      <Link to="/ch-home">
        <div className="component-52-1 border-1px-dove-gray">
          <div className="text-1 argentcf-super-normal-licorice-15px">{text1}</div>
        </div>
      </Link>
      <div className="component-73-1">
       
        <div className="component-72-1">
          <img className="croods5" src={croods5} />
          <Component701 asset1={component701Props.asset1} x23={component701Props.x23} />
        </div>
      </div>
       <div className="text-2 poppins-normal-licorice-20px">
         {text2}
         </div>
      <Link to="/ch-typeform-home" >
        <div className="component-45-1">
          <div className="get-invite-code poppins-normal-licorice-25px">{getInviteCode}</div>
        </div>
      </Link>
    </div>
  );
}

export default Enhome;
