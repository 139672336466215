import React from "react";
import {  Switch, BrowserRouter as Router, Route} from "react-router-dom";
import Encods from "./components/Encods";
import Chcods from "./components/Chcods";
import Enhome from "./components/Enhome";
import Chhome from "./components/Chhome";
// import Enhcaptcha from "./components/Enhcaptcha";
// import Chhcaptcha from "./components/Chhcaptcha";
import Entypeform_home from "./components/Entypeform_home";
import Chtypeform_home from "./components/Chtypeform_home";
import Entypeform_fail from "./components/Entypeform_fail";
import Chtypeform_fail from "./components/Chtypeform_fail";
import Entypeform_pass from "./components/Entypeform_pass";
import Chtypeform_pass from "./components/Chtypeform_pass";
// import { useAnalytics } from 'use-analytics';
import Codes from "./components/Codes";
import "./App.css";


function App() {
  //let locations= useLocation()
  // const analytics =useAnalytics()

// useEffect(()=>{
//   analytics.page();
// },[locations])

  return (
   
      <Switch>
        <Route path="/en-cods">
          <Encods {...EncodsData} />
        </Route>
        <Route path="/ch-cods">
          <Chcods {...ChcodsData} />
        </Route>
        <Route path="/en-home">
          <Enhome {...EnhomeData} />
        </Route>
        <Route path="/:path(|ch-home)">
          <Chhome {...ChhomeData} />
        </Route>
        <Route path="/codes">
         <Codes/>
        </Route>
        {/* <Route path="/en-hcaptcha">
          <Enhcaptcha logo_V="/img/logo-v-10@1x.png" captcha="/img/captcha-1@1x.png" getInviteCode="Get invite code" />
        </Route>
        <Route path="/ch-hcaptcha">
          <Chhcaptcha logo_V="/img/logo-v-10@1x.png" captcha="/img/captcha-1@1x.png" text6="获取邀请吗" />
        </Route> */}
        <Route path="/en-typeform-home">
          <Entypeform_home
            logo_V="/img/logo-v-10@1x.png"
            x3="/img/3@1x.png"
            proveYourself="Prove yourself"
            line1="/img/line-1-1@1x.png"
            text7={
              <>
                Are you <br />a good <br />
                fit?
              </>
            }
          />
        </Route>
        <Route path="/ch-typeform-home">
          <Chtypeform_home
            logo_V="/img/logo-v-10@1x.png"
            text8="证明一下自己"
            x3="/img/3@1x.png"
            line1="/img/line-1-1@1x.png"
            ta={
              <>
                你是那个
                <br />
                合适的ta吗？
              </>
            }
          />
        </Route>
        <Route path="/en-typeform-fail">
          <Entypeform_fail
            logo_V="/img/logo-v-10@1x.png"
            line1="/img/line-1-1@1x.png"
            x105413X="/img/file--10541-3x@1x.png"
            leave="Leave"
            oops="Oops…"
          />
        </Route>
        <Route path="/ch-typeform-fail">
          <Chtypeform_fail
            logo_V="/img/logo-v-10@1x.png"
            line1="/img/line-1-1@1x.png"
            text9="离开"
            x105413X="/img/file--10541-3x@1x.png"
            nine="您暂时无法加入nine"
            text10="抱歉..."
          />
        </Route>
        <Route path="/en-typeform-pass">
          <Entypeform_pass
            logo_V="/img/logo-v-10@1x.png"
            next="Next"
            perfect="Perfect."
            text11="Welcome to our community "
            component222="/img/component-22---2@1x.png"
          />
        </Route>
        <Route path="/ch-typeform-pass">
          <Chtypeform_pass
            logo_V="/img/logo-v-10@1x.png"
            rectangle28="/img/rectangle-28@1x.png"
            text12="继续"
            group43="/img/component-22---2@1x.png"
            text13="欢迎加入我们的社区"
            perfect="Perfect."
          />
        </Route>
      </Switch>
    
  );
}

export default App;









const component931Data = {
    xyz2423: "XYZ2423",
    x24: "/img/24-1@1x.png",
};

const component9312Data = {
    xyz2423: "XYZ2423",
    x24: "/img/24-1@1x.png",
};

const component9313Data = {
    xyz2423: "XYZ2423",
    x24: "/img/24-1@1x.png",
};

const component9314Data = {
    xyz2423: "XYZ2423",
    x24: "/img/24-1@1x.png",
};

const EncodsData = {
    logo_V: "/img/logo-v-10@1x.png",
    enjoy: "Enjoy",
    x7: "/img/7@1x.png",
    line2: "/img/line-2-1@1x.png",
    x25: "/img/25-1@1x.png",
    inputName: "email-my-codes2",
    inputType: "email",
    inputPlaceholder: "Email my codes / 发送邮箱",
    inputRequired: "true",
    downloadTheApp: "Download the APP",
    appStore: "/img/app-store-1@1x.png",
    android: "/img/android-1@1x.png",
    component931Props: component931Data,
    component9312Props: component9312Data,
    component9313Props: component9313Data,
    component9314Props: component9314Data,
};

const component475Data = {
    children: "XYZ2423",
};

const component485Data = {
    x24: "/img/24-1@1x.png",
    component475Props: component475Data,
};

const component4752Data = {
    children: "XYZ2423",
};

const component4852Data = {
    x24: "/img/24-1@1x.png",
    component475Props: component4752Data,
};

const component4753Data = {
    children: "XYZ2423",
};

const component4754Data = {
    children: "XYZ2423",
};

const component4853Data = {
    x24: "/img/24-1@1x.png",
    component475Props: component4754Data,
};

const ChcodsData = {
    logo_V: "/img/logo-v-10@1x.png",
    line2: "/img/line-2-1@1x.png",
    line22: "/img/line-2-1@1x.png",
    x25: "/img/25-1@1x.png",
    x252: "/img/25-1@1x.png",
    inputName: "发我的请吗4",
    inputType: "email",
    inputPlaceholder: "发我的请码",
    inputRequired: "false",
    x24: "/img/24-1@1x.png",
    enjoy: "Enjoy",
    x7: "/img/7@1x.png",
    android: "/img/android-1@1x.png",
    appStore: "/img/app-store-1@1x.png",
    downloadTheApp: "Download the APP",
    component485Props: component485Data,
    component4852Props: component4852Data,
    component475Props: component4753Data,
    component4853Props: component4853Data,
};

const component701Data = {
    asset1: "/img/asset-1-1@1x.png",
    x23: "/img/23-1@1x.png",
};

const EnhomeData = {
    logo_V: "./img/logo-v-10@1x.png",
    x1: "./img/1@1x.png",
    asset1: "/img/asset-1-2@1x.png",
    title: <>Date<br />With <br />Your</>,
    text1: "中",
    text2: <>Make authenticconnections with<br /> 
            9 urban professionals every day.</>,
    croods5: "/img/croods5-1@1x.png",
    getInviteCode: "Get invite code",
    component701Props: component701Data,
};

const component7012Data = {
    asset1: "/img/asset-1-1@1x.png",
    x23: "/img/23-1@1x.png",
};

const ChhomeData = {
    logo_V: "/img/logo-v-10@1x.png",
    x1: "/img/1@1x.png",
    asset1: "/img/asset-1-2@1x.png",
    text3: <>用真  <br />来交友</>,
    en: "en",
    text4: <>每天与9位城市中“年轻有为”且<br />有趣的人建立真实的关系。</>,
    croods5: "/img/croods5-1@1x.png",
    text5: "获取邀请码",
    component701Props: component7012Data,
};

