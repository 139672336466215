import React from "react";
import Component475 from "../Component475";
import "./Component485.css";

function Component485(props) {
  const { x24, component475Props, className } = props;

  return (
    <div className={`component-48-5 ${className || ""}`}>
      <Component475>{component475Props.children}</Component475>
      <img className="x243" src={x24} />
    </div>
  );
}

export default Component485;
