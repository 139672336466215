import React, { useState, useEffect } from "react";
//import Code11 from "../Code11";
import "./Codes.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Codes() {
  const codesData = {
    maskGroup55: "/img/mask-group-55@1x.png",
    app: "保存APP邀请码",
    maskGroup56: "/img/mask-group-52@1x.png",
    x25: "/img/25@1x.png",
    text2: "邮箱发送邀请码",
    path6: "/img/path-6@1x.png",
    inputType: "email",
    inputPlaceholder: "@",
    maskGroup53: "/img/mask-group-53@1x.png",
    text3: "⼈⼯⼿机截图/ 复制",
    xyz2423: "XYZ2423",
    asset8: "/img/asset-8@1x.png",
    text4: "全部复制",
    path10: "/img/iconSend.png",
    path11: "/img/path-11@1x.png",
    text5: "发送",
    maskGroup54: "/img/mask-group-54@1x.png",
    spanText: "下载[",
    spanText2: "nine",
    spanText3: "你呢]",
    spanText4: "APP",
    appStore: "/img/app-store@1x.png",
    android: "/img/android@1x.png",
    spanText5: "(",
    spanText6: "未上线",
    spanText7: ")"
};



const [codes, setCodes] = useState([]);
const [email, setEmail] = useState("");


const notify = () => {
  toast("邀请码已被拷贝",{position:toast.POSITION.TOP_RIGHT, autoClose:false})
  // track("codeCopied");
};

useEffect(async () => {
  await fetch("https://api.meetnine.cn/api/invite-codes/referral")
    .then((res) => res.json())
    .then((json) => setCodes(json.data));
}, []);

const handleOnChangeEmail = (e) => {
  setEmail(e.target.value);
};


const handleSubmit = (e) => {
  e.preventDefault();
  toast("您的邀请码已通过邮箱发送！", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: false,
  });
  
  // console.log("Email codes to: " + email);
  try {
    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, codes: codes }),
    };
    fetch(
      " https://api.meetnine.cn/api/invite-codes/referral/send",  requestOptions
    );
  } catch (e) {
    console.log(e);
  }

 console.log("mail sent to "+ email)

};





function Code11(props) {
  const { xyz2423, line3, className } = props;
  return (
    <div className={`code11 ${className || ""}`}>
      <div className="xyz2423-1 poppins-medium-licorice-14px">{xyz2423}</div>
      <img className="line-3" src={line3} />
    </div>
  );
}

  
  const code11Data = {
    xyz2423: "XYZ2423",
    line3: "/img/line-3-1@1x.png",
  };
  
  const code112Data = {
    xyz2423: "XYZ2423",
    line3: "/img/line-3-1@1x.png",
  };
  
  const code113Data = {
    xyz2423: "XYZ2423",
    line3: "/img/line-3-1@1x.png",
  };

  return (
    <div class="container-center-horizontal">
      <form className="codes screen" name="form1" onSubmit={handleSubmit} >
        <img className="mask-groupiii" src={codesData.maskGroup55} />
        <div className="component-31-1">
          <div className="component-28-1">
            <div className="component-25-1">
              <div className="overlap-group2i">
                <div className="appi poppins-bold-licorice-20px">
                <span className="span">保存</span>
                  <span className="span-1">APP</span>
                  <span className="span">邀请码</span>
                  
                  </div>
                <img className="mask-group-56i" src={codesData.maskGroup56} />
                
                <div className="overlap-groupi">
                  <div className="envelopentxt">
                    <img className="x25i" src={codesData.x25} />
                    <div className="textii poppins-medium-licorice-14px">{codesData.text2}</div>
                  </div>
                  <img className="path-6" src={codesData.path6} />
                  


                  <input
                    className="xl poppins-bold-licorice-16px"
                    name="8"
                    placeholder="@"
                    type="email"
                    onChange={handleOnChangeEmail}
                    required
                  />
                </div>
                <div className="phonentxt">
                  <img className="mask-groupi" src={codesData.maskGroup53} />
                  <div className="texta poppins-medium-licorice-14px">{codesData.text3}</div>
                </div>
                <img
                    className="rgba25475511"
                    src="https://anima-uploads.s3.amazonaws.com/projects/6078ca6bacb6a8caea6ac83d/releases/607a3fc4cc68d56939ab0068/img/rgba-254-75-51-1-@1x.png"
                  />
                <Code11 xyz2423={codes[0]} line3={code11Data.line3} />
                <Code11 xyz2423={codes[1]} line3={code112Data.line3} className="code21" />
                <Code11 xyz2423={codes[2]} line3={code113Data.line3} className="code31" />
                <div className="component-24-1">
                  <div className="xyz2423i poppins-medium-licorice-14px">{codes[3]}</div>
                  
                <CopyToClipboard text={"Code 1: "+codes[0]+".   Code 2: "+codes[1]+".   Code 3: "+codes[2]+".   Code 4:"+codes[3] }>
                  <div className="copycomp" onClick={notify}>
                    <img className="asset-8" src={codesData.asset8} />
                    <div className="texto poppins-medium-licorice-12px">{codesData.text4}</div>
                  </div>
                </CopyToClipboard>
                   </div>
              </div>
              
                <div className="sendcomp" >
                  <div className="icon-feather-send">
                    <div className="overlap-group1i">

                  <input
                  type="image"
                  name="submit"
                  className="path-11"
                  src={codesData.path10}
                  // onClick={handleSubmit}
                /> 
                     
                      

                    </div>
                  </div>
                  <div className="text-5i poppins-bold-licorice-13px">{codesData.text5}</div>
                </div>
              
            </div>
          </div>
          <div className="component-29-1">
            <div className="component-27-1">
              <div className="title2i">
                <img className="mask-groupii" src={codesData.maskGroup54} />
                <div className="nineapp poppins-light-licorice-20px">
                  <span className="span">{codesData.spanText}</span>
                  <span className="span-1">{codesData.spanText2}</span>
                  <span className="span">{codesData.spanText3}</span>
                  <span className="span-1">{codesData.spanText4}</span>
                </div>
              </div>
              <div className="component-26-1">
              <a href="https://apps.apple.com/cn/app/nine%E4%BD%A0%E5%91%A2/id1540477539">
                <img className="app-store" src={codesData.appStore} />
                </a>
                <img className="android" src={codesData.android} />
              </div>
              <div className="texti poppins-bold-licorice-13px">
                <span className="text-5i poppins-bold-licorice-13px">{codesData.spanText5}</span>
                <span className="text-5i poppins-bold-licorice-10px">{codesData.spanText6}</span>
                <span className="text-5i poppins-bold-licorice-13px">{codesData.spanText7}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Codes;
