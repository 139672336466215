import React from "react";
import "./Component475.css";

function Component475(props) {
  const { children } = props;

  return (
    <div className="component-47-5">
      <div className="xyz24232 poppins-light-licorice-21px">{children}</div>
    </div>
  );
}

export default Component475;
