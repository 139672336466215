import React, {useState} from "react";
import "./Component931.css";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useAnalytics } from 'use-analytics'

toast.configure()
function Component931(props) {
  const { xyz2423, x24, className } = props;
  // const { track } =useAnalytics();

const notify = () => {
  toast("邀请码已被拷贝",{position:toast.POSITION.TOP_RIGHT, autoClose:false})
  // track("codeCopied");
};


  return (
    <div className={`component-93-1 ${className || ""}`}>
      <div className="overlap-group">
        <div className="xyz2423 poppins-light-licorice-21px">{xyz2423}</div>
      </div>
      {/* <button onClick={notify}>yippy</button> */}
      <CopyToClipboard text={xyz2423}>
        <div onClick={notify}>
     <img className="x24"  src={x24} /></div>
      </CopyToClipboard>
    </div>
  );
}

export default Component931;
