import React from "react";
import { Link } from "react-router-dom";
import "./Chtypeform_home.css";
import { ReactTypeformEmbed } from 'react-typeform-embed';



  

 
class Chtypeform_home extends React.Component {
  render() {
    return (
    <ReactTypeformEmbed url="https://form.typeform.com/to/jTuENbJM" />
    );
  }
}
  

export default Chtypeform_home;
