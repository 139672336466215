import React from "react";
import { Link } from "react-router-dom";
import Component701 from "../Component701";
import "./Chhome.css";

function Chhome(props) {
  const {
    logo_V,
    x1,
    asset1,
    text3,
    en,
    text4,
    croods5,
    text5,
    component701Props,
  } = props;

  return (
    <div className="ch-home screen ">
      <img className="logov4" src={logo_V} />
      <div className="component-71-3">
        <img className="x12" src={x1} />
        <div
          className="asset-13"
          style={{ backgroundImage: `url(${asset1})` }}
        ></div>
        <div className="component-43-12">
          <div className="text-3 argentcf-super-normal-licorice-50px">
            {text3}
          </div>
        </div>
      </div>
      <Link to="/en-home">
        <div className="component-52-2 border-1px-dove-gray">
          <div className="en argentcf-super-normal-licorice-15px">{en}</div>
        </div>
      </Link>
        
      <div className="component-73-3">
        <div className="component-72-12">
          <img className="croods52" src={croods5} />
          <Component701
            asset1={component701Props.asset1}
            x23={component701Props.x23}
          />
        </div>
      </div>
      
      <div className="text-4 poppins-normal-licorice-20px">
          {text4}
        </div>
      <Link to="/en-typeform-home">
        <div className="component-45-8">
          <div className="text-5 poppins-normal-licorice-25px">{text5}</div>
        </div>
      </Link>
    </div>
  );
}

export default Chhome;
