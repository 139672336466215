import React from "react";
import { Link } from "react-router-dom";
import "./Chtypeform_fail.css";

function Chtypeform_fail(props) {
  const { logo_V, line1, text9, x105413X, nine, text10 } = props;

  return (
    <div className="ch-typeform-fail screen ">
      <Link to="/ch-home">
        <img className="logov10" src={logo_V} />
      </Link>
      <img className="line-14" src={line1} />
      <Link to="/ch-typeform-pass">
        <div className="component-62-2">
          <div className="text-9 poppins-normal-licorice-25px">{text9}</div>
        </div>
      </Link>
      <img className="x105413x2" src={x105413X} />
      <div className="nine">{nine}</div>
      <div className="text-10 argentcf-super-normal-licorice-45px">{text10}</div>
    </div>
  );
}

export default Chtypeform_fail;
