import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Component485 from "../Component485";
import Component475 from "../Component475";
import "./Chcods.css";

function Chcods(props) {
  const {
    logo_V,
    line2,
    line22,
    x25,
    x252,
    inputName,
    inputType,
    inputPlaceholder,
    inputRequired,
    x24,
    enjoy,
    x7,
    android,
    appStore,
    downloadTheApp,
    component485Props,
    component4852Props,
    component475Props,
    component4853Props,
  } = props;



  return (
   
    <div>
    <form className="ch-cods screen " name="form3" action="form3" method="post">
      <Link to="/ch-home">
        <img className="logov2" src={logo_V} />
      </Link>
      <div className="group-46">
        <div className="component-49-1">
          <img className="line-22" src={line2} />
          <img className="line-2" src={line22} />
          <a href="javascript:SubmitForm('form3')">
            <img className="x252" src={x25} />
          </a>
          <a href="javascript:SubmitForm('form3')">
            <img className="x25-1 animate-enter1" src={x252} />
          </a>
          <input
            className="textinput2 poppins-bold-dove-gray-16px"
            name={inputName}
            placeholder={inputPlaceholder}
            type={inputType}
            required={inputRequired}
          />
        </div>
        <Component485 x24={component485Props.x24} component475Props={component485Props.component475Props} />
        <Component485
          x24="{component4852Props.x24}"
          component475Props={component4852Props.component475Props}
          className="component-48-6"
        />
        <div className="component-48-7">
          <Component475>{component475Props.children}</Component475>
          <img className="x242" src={x24} />
        </div>
        <Component485
          x24="{component4853Props.x24}"
          component475Props={component4853Props.component475Props}
          className="component-48-8"
        />
      </div>
      <div className="enjoy2 argentcf-super-normal-licorice-45px">{enjoy}</div>
      <img className="x72" src={x7} />
      <div className="android2">
        <img className="android-1" src={android} />
      </div>
      <div className="app-store2">
        <img className="app-store-1" src={appStore} />
      </div>
      <div className="download-the-app2 poppins-bold-licorice-15px">{downloadTheApp}</div>
    </form>
    </div>
  );
}

export default Chcods;
