import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';

import "./style.css"
// import amplitude from 'amplitude-js';

// amplitude.getInstance().init("ca4b52bcf360a34322f38c5fd9c92116");
// const analytics=Analytics({ 
//   app: 'Invite9',
// plugins: [
//   amplitudePlugin({
//   apiKey: 'ca4b52bcf360a34322f38c5fd9c92116',
//   // See options at https://bit.ly/3dRdZnE
//   options: {
//     trackingOptions: {
//       ip_address: false
//     }
//   }
// })]
// })

// console.log(analytics);

ReactDOM.render((
  // <AnalyticsProvider instance={analytics}>
  <React.StrictMode>
  <Router>
    <App />
    </Router>
  </React.StrictMode>
  //</AnalyticsProvider>
), document.getElementById('app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
