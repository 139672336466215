import React from "react";
import "./Component701.css";

function Component701(props) {
  const { asset1, x23 } = props;

  return (
    <div className="component-70-1">
      <img className="asset-12" src={asset1} />
      <img className="x23" src={x23} />
    </div>
  );
}

export default Component701;
