import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Component931 from "../Component931";
import "./Encods.css";
// import { useAnalytics } from 'use-analytics';

import { toast } from "react-toastify";

toast.configure();

function Encods(props) {
  const {
    logo_V,
    enjoy,
    x7,
    line2,
    x25,
    inputName,
    inputType,
    inputPlaceholder,
    inputRequired,
    downloadTheApp,
    appStore,
    android,
  } = props;

  // const { track } =useAnalytics();

  const component9312Data = {
    xyz2423: "XYZ2423",
    x24: "/img/24-1@1x.png",
  };

  const [codes, setCodes] = useState([]);
  const [email, setEmail] = useState("");


  useEffect(async () => {
    await fetch("https://api.meetnine.cn/api/invite-codes/referral")
      .then((res) => res.json())
      .then((json) => setCodes(json.data));
  }, []);

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast("您的邀请码已通过邮箱发送！", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
    });
    
    // console.log("Email codes to: " + email);
    try {
      const requestOptions = {
        method: "POST",
        // mode: "no-cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, codes: codes }),
      };
      fetch(
        " https://api.meetnine.cn/api/invite-codes/referral/send",  requestOptions
      );
    } catch (e) {
      console.log(e);
    }
   

  };

  

  return (
    <div>
      <div class="container-center-horizontal">
        {console.log(codes)}
        <form
          className="en-cods screen"
          name="email-codes"
          onSubmit={handleSubmit}
        >
          <div className="flex-row">
            <div className="flex-col">
              <Link to="/">
                <img className="logov" src={logo_V} />
              </Link>
              <div className="enjoy argentcf-super-normal-licorice-45px">
                {enjoy}
              </div>
            </div>
            <img className="x7" src={x7} />
          </div>

          <div className="group-48">
            {codes.map((code) => (
              <div key={code.index}>
                <Component931
                  xyz2423={code}
                  x24={component9312Data.x24}
                  className="component-93-"
                />
              </div>
            ))}
          </div>
          <div className="component-94-1">
            <div className="overlap-group4">
              <img className="line-2" src={line2} />

              <input
                className="email-my-codes2 poppins-bold-dove-gray-16px"
                name={inputName}
                placeholder={inputPlaceholder}
                type={inputType}
                required={inputRequired}
                onChange={handleOnChangeEmail}
                onSubmit={handleSubmit}
              />
              
                <input
                  type="image"
                  name="submit"
                  className="x25 animate-enter"
                  src={x25}
                  onClick={handleSubmit}
                />
              
            </div>
          </div>
          <div className="download-the-app poppins-bold-licorice-15px">
            {downloadTheApp}
          </div>
          <div className="flex-row-1">
            <a href="https://apps.apple.com/cn/app/nine%E4%BD%A0%E5%91%A2/id1540477539">
              <img className="app-store" src={appStore} />
            </a>
            <img className="android" src={android} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Encods;
