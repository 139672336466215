import React from "react";
import { Link } from "react-router-dom";
import "./Chtypeform_pass.css";

function Chtypeform_pass(props) {
  const { logo_V, rectangle28, text12, group43, text13, perfect } = props;

  return (
    <div className="ch-typeform-pass screen ">
      <Link to="/ch-home">
        <img className="logov12" src={logo_V} />
      </Link>
      <Link to="/ch-cods">
        <div className="component-64-1">
          <img className="rectangle-28" src={rectangle28} />
          <div className="text-12 poppins-normal-licorice-25px">{text12}</div>
        </div>
      </Link>
      <div className="component-89-1">
        <img className="group-43" src={group43} />
        <div className="text-13 poppins-normal-licorice-20px">{text13}</div>
        <div className="perfect2 argentcf-super-normal-licorice-45px">{perfect}</div>
      </div>
    </div>
  );
}

export default Chtypeform_pass;
