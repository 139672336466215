import React from "react";
import { Link } from "react-router-dom";
import "./Entypeform_pass.css";

function Entypeform_pass(props) {
  const { logo_V, next, perfect, text11, component222 } = props;

  return (
    <div className="en-typeform-pass screen ">
      <Link to="/en-home">
        <img className="logov11" src={logo_V} />
      </Link>
      <Link to="/en-cods">
        <div className="component-45-5">
          <div className="next poppins-normal-licorice-25px">{next}</div>
        </div>
      </Link>
      <div className="component-69-1">
        <div className="component-67-1">
          <div className="component-65-1">
            <div className="perfect argentcf-super-normal-licorice-45px">{perfect}</div>
          </div>
          <div className="component-66-1">
            <div className="text-11">{text11}</div>
          </div>
        </div>
        <img className="component-22-2" src={component222} />
      </div>
    </div>
  );
}

export default Entypeform_pass;
